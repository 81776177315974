import {
    CLEAR_KLINE, CHANGE_STOCK_DATA, CHANGE_QUOTE_DATA, CHANGE_MARKET, DEFAULT_ACCOUNT, CHANGE_CODE, CHANGE_PERIOD,
    CHANGE_LAST_KLINE, CHANGE_UUID, SET_PROFIT_LINE, CLEAR_PROFIT_LINE, CHANGE_DIRECTION, CAHNAGE_PROFIT_ID, SET_CURRENTPOSITION, SET_HOLD_ORDER_LINE, CLEAR_HOLD_ORDER_LINE,
    SET_LAST_TIME, SET_SIGNAL_LIST, SET_CURRENTPOSITION_LIST, SET_CURRENT_FUT_ACCOUNT, SET_WEB_SOCKET_CONNECT_STATUS, SET_SUB_ACCOUNT_MESSAGE, SET_POOL_ITEM, SET_POOL_STOCK_LIST,
    CHANGE_STOCK_REGION_INFO, SET_LIDE_DATA,CLEAR_LIDE_DATA

} from './actionTypes'
import { cloneDeep } from 'lodash'
import moment from 'moment';

function randomString(length) {
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
}

const defaultState = {
    code: "000001.SS",
    // code: '',
    period: 6,
    isFavor: false,
    stockDate: [],
    quote: { prod_name: "", prod_code: "" },
    marketType: 0, //0股票，1期货
    defaultAccount: {},
    currentFutAccount: {},
    subAccountMessage: {},
    socketUuid: "",
    // profitLine:[{name:'开仓价',price:60,},{name:'止盈价',price:65,},{name:'止损价',price:55,}], //开仓价 止盈 止损  数据列表
    profitLine: [],
    currentDirection: 0,
    positionId: 0,
    currentPosition: {},
    currentPositionList: [],
    lastTime: "",
    signalList: [],
    randomString: randomString(10),
    isWebsocketConnect: false,
    poolItem: {},
    poolStockList: [],
    stockRegionInfo: {},
    lideData: {}
}

function mergeLdData(stockData, lideData) {

    for (let key in lideData) {
        stockData = stockData.map(dataitem => {
            let lideItem = lideData[key]
            const len = lideItem.length
            for (let i = 0; i < len; i++) {
                if (moment(dataitem.date).format('YYYYMMDDHHmm') === moment(lideItem[i].date).format('YYYYMMDDHHmm')) {
                    dataitem[key] = lideItem[i].value
                    break
                }
            }
            return dataitem
        })
    }
 
    return stockData
}


const reducer = (state = defaultState, action) => {

    switch (action.type) {

        case CHANGE_STOCK_REGION_INFO: {
            return {
                ...state, stockRegionInfo: action.data
            }
        }
        case CHANGE_CODE: {
            return {
                ...state, code: action.data
            }
        }
        case CHANGE_PERIOD: {
            return {
                ...state, period: action.data
            }
        }
        case CLEAR_KLINE: {
            return {
                ...state, stockDate: []
            }
        }
        case CHANGE_STOCK_DATA: {
            let data = mergeLdData(action.data, state.lideData)
            return {
                ...state, stockDate: data
            }
        }
        case CHANGE_QUOTE_DATA: {
            if (!action.data) {
                return {
                    ...state, quote: {
                        ...state.quote,
                        px_change_rate: "--", last_px: "--"
                    }
                }
            } else {
                return {
                    ...state, quote: { ...state.quote, ...action.data, }
                }
            }

        }
        case CHANGE_LAST_KLINE: {
            let stockDate = cloneDeep(state.stockDate)
            const len = stockDate.length
            const result = action.data
            // console.log(result.date.toLocaleString())
            // console.log(result.date.toLocaleString())

            if (stockDate.length > 0) {

                if (stockDate[len - 1].date.toLocaleString() === result.date.toLocaleString()) {
                    stockDate[len - 1] = result
                } else if (result.date > stockDate[len - 1].date) {

                    stockDate.push(result)
                }
                let data = mergeLdData(stockDate, state.lideData)
                return {
                    ...state, stockDate: data
                }
            } else {
                return {
                    ...state
                }
            }
        }

        case SET_LIDE_DATA: {
            const { name, value } = action.data
            let lideData = { ...state.lideData, [name]: value }
            let data = mergeLdData(state.stockDate, lideData)
            return {
                ...state, lideData: lideData, stockDate: data
            }
        }

        case CLEAR_LIDE_DATA:{
            return {...state,lideData:{}}
        }

        case CHANGE_MARKET: {
            return {
                ...state, marketType: action.data
            }
        }
        case DEFAULT_ACCOUNT: {
            return {
                ...state, defaultAccount: action.data
            }
        }
        case CHANGE_UUID: {
            return {
                ...state, socketUuid: action.data
            }
        }
        case SET_PROFIT_LINE: {
            const cancelOrder = state.profitLine.filter(item => item.type === 'cancelOrder')
            return {
                ...state, profitLine: [...action.data, ...cancelOrder]
            }
        }

        case CLEAR_PROFIT_LINE: {
            return {
                ...state, profitLine: []
            }
        }

        case CHANGE_DIRECTION: {
            return {
                ...state, currentDirection: action.data
            }
        }
        case CAHNAGE_PROFIT_ID: {
            return {
                ...state, positionId: action.data
            }
        }
        case SET_CURRENTPOSITION: {
            return {
                ...state, currentPosition: action.data
            }
        }

        case SET_CURRENTPOSITION_LIST: {
            return {
                ...state, currentPositionList: action.data
            }
        }

        case SET_HOLD_ORDER_LINE: {
            return {
                ...state, profitLine: [...state.profitLine, ...action.data]
            }
        }

        case CLEAR_HOLD_ORDER_LINE: {
            return {
                ...state, profitLine: state.profitLine.filter(item => item.type !== 'cancelOrder')
            }
        }

        case SET_LAST_TIME: {
            return {
                ...state, lastTime: action.data
            }
        }

        case SET_SIGNAL_LIST: {
            return {
                ...state, signalList: action.data
            }
        }

        case SET_CURRENT_FUT_ACCOUNT: {
            return {
                ...state, currentFutAccount: action.data
            }
        }

        case SET_WEB_SOCKET_CONNECT_STATUS: {
            return {
                ...state, isWebsocketConnect: action.data
            }
        }

        case SET_SUB_ACCOUNT_MESSAGE: {
            return {
                ...state, subAccountMessage: action.data
            }
        }

        case SET_POOL_ITEM: {
            return {
                ...state, poolItem: action.data
            }
        }
        case SET_POOL_STOCK_LIST: {
            return {
                ...state, poolStockList: action.data
            }
        }
        default:
            break
    }
    return state
}

export default reducer
